import React, { useCallback, useEffect, useMemo } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Typography } from '../../common';
import { MetaData } from './meta-data';
import { Targeting } from './targeting';
import { CreativeForm } from './creative-form';
import classnames from 'classnames';
import './line-item-create.scss';
import { isEmpty } from 'lodash';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import LandingPage from './landing_page';

const StepperComponent = ({ steps = [], activeStep = 0, setActiveStep }) => {
  return (
    <div className="stepper-component">
      <Stepper activeStep={activeStep}>
        {steps.map((step, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={step.title}
              {...stepProps}
              onClick={() => setActiveStep(index)}
              className={classnames('cursor-pointer', {
                disabled: !step.isEnabled,
              })}
            >
              <StepLabel {...labelProps}>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>{steps[activeStep].component}</div>
    </div>
  );
};

export const LineItemCreate = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const hash = location?.hash;
  const queryParams = queryString.parse(location?.search);
  const selectedTitleFromURL = queryParams.selectedTitle;
  const {
    // advertiserId = '',
    campaignId = '',
    lineItemId: lineItemIdCreate,
    timezone,
  } = queryParams;
  const { lineItemId = '' } = useParams();
  let { agencyId = '' } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isMetaPresent, setIsMetaPresent] = React.useState(false);

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: `/advertiser/line-item?advertiserId=${advertiserId}&campaignId=${campaignId}`,
        },
      }),
    );
  }, [dispatch]);

  const { data: lineItemData = {} } = useQuery(
    ['LINE_ITEM_DATA', lineItemId, agencyId],
    async () => {
      const payload = {
        agencyId,
      };
      const id = lineItemId ? lineItemId : lineItemIdCreate;
      const response = await Services.lineItemGetDetails(
        advertiserId,
        campaignId,
        id,
        queryString.stringify(payload),
      );
      return response.data?.lineItemDTO;
    },
    { enabled: (!!lineItemId || !!lineItemIdCreate) && !!agencyId },
  );

  let lineItemObjective = lineItemData?.lineItemObjective;
  const payload = useMemo(
    () => ({
      advertiserId,
      campaignId,
      lineItemId,
      agencyId,
    }),
    [advertiserId, campaignId, lineItemId, agencyId],
  );

  const metaDataComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
    };
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '1',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '1',
        search: queryString.stringify({ advertiserId, campaignId }),
      });
    }
  };

  const creativesComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
    };
    if (!lineItemId) {
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '2',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '2',
        search: queryString.stringify({ advertiserId, campaignId }),
      });
    }
  };

  const targetingComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
    };

    // Check if 'Lead Form' step is present
    const isLeadFormStepPresent = getSteps().some((step) => step.id === 3);

    if (!isLeadFormStepPresent) {
      // If 'Lead Form' step is not present, navigate to the desired path
      history.push({
        pathname: '/advertiser/line-item',
        search: queryString.stringify(payload),
      });
    } else if (!lineItemId) {
      // If 'Lead Form' step is present and it's a new line item, navigate accordingly
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '3',
        search: queryString.stringify(payload),
      });
    } else {
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '3',
        search: queryString.stringify({ advertiserId, campaignId }),
      });
    }
  };

  const lineItemComplete = (newLIid) => {
    setIsMetaPresent(true);
    const payload = {
      advertiserId,
      campaignId,
      lineItemId: newLIid,
    };

    // Check if 'Lead Form' step is present
    const isLeadFormStepPresent = getSteps().some((step) => step.id === 3);

    if (!isLeadFormStepPresent) {
      // If 'Lead Form' step is not present, navigate to the desired path
      history.push({
        pathname: '/advertiser/line-item',
        search: queryString.stringify(payload),
      });
    } else if (!lineItemId) {
      // If 'Lead Form' step is present and it's a new line item, navigate accordingly
      history.push({
        pathname: '/advertiser/line-item-create',
        hash: '3',
        search: queryString.stringify(payload),
      });
    } else {
      // If 'Lead Form' step is present and it's an existing line item, navigate accordingly
      history.push({
        pathname: `/advertiser/line-item/${lineItemId}`,
        hash: '3',
        search: queryString.stringify({ advertiserId, campaignId }),
      });
    }
  };

  const LandingPageComplete = () => {
    const payload = {
      advertiserId,
      campaignId,
    };
    history.push({
      pathname: '/advertiser/line-item',
      search: queryString.stringify(payload),
    });
  };
  useEffect(() => {
    const steps = getSteps(true);
    if (!isEmpty(steps) && !!hash) {
      handleStepClick(parseInt(hash?.split('#')[1]), steps);
    }
  }, [hash]);

  const getSteps = useCallback(
    (isMetaSubmit = false) => {
      const commonSteps = [
        {
          id: 0,
          title: 'Meta Data',
          isEnabled: true,
          component: (
            <MetaData
              lineItemData={lineItemData}
              payload={payload}
              goToNextStep={metaDataComplete}
              timezone={timezone}
              selectedTitleFromURL={selectedTitleFromURL}
            />
          ),
        },
        {
          id: 1,
          title: 'Creative',
          isEnabled: true,
          component: (
            <CreativeForm
              campaignId={campaignId}
              advertiserId={advertiserId}
              lineItemId={lineItemIdCreate || lineItemId}
              agencyId={agencyId}
              expired={lineItemData.expired}
              goToNextStep={creativesComplete}
              lineItemObjective={lineItemData?.lineItemObjective}
            />
          ),
        },
        {
          id: 2,
          title: 'Targetings',
          isEnabled: true,
          component: (
            <Targeting
              campaignId={campaignId}
              advertiserId={advertiserId}
              lineItemId={lineItemIdCreate || lineItemId}
              agencyId={agencyId}
              expired={lineItemData.expired}
              goToNextStep={targetingComplete}
            />
          ),
        },
        // {
        //   id: 3,
        //   title: 'Adv Targetings',
        //   isEnabled: true,
        //   component: (
        //     <AdvTargeting
        //       campaignId={campaignId}
        //       advertiserId={advertiserId}
        //       lineItemId={lineItemIdCreate || lineItemId}
        //       agencyId={agencyId}
        //       expired={lineItemData.expired}
        //       goToNextStep={lineItemComplete}
        //       lineItemObjective={lineItemData?.lineItemObjective}
        //     />
        //   ),
        // },
      ];

      if (selectedTitleFromURL === 'LEAD' || lineItemData?.lineItemObjective === 'LEAD') {
        return [
          ...commonSteps,
          {
            id: 3,
            title: 'Lead Form',
            isEnabled: true,
            component: (
              <LandingPage
                campaignId={campaignId}
                advertiserId={advertiserId}
                lineItemId={lineItemIdCreate || lineItemId}
                agencyId={agencyId}
                expired={lineItemData.expired}
                goToNextStep={LandingPageComplete}
              />
            ),
          },
        ];
      } else {
        return commonSteps;
      }
    },
    [lineItemData, isMetaPresent, selectedTitleFromURL],
  );

  const handleStepClick = useCallback(
    (index, steps = getSteps()) => {
      const selectedStep = steps.find((item) => item.id === index);
      if (selectedStep?.isEnabled) {
        setActiveStep(index);

        const newHash = `#${index}`;
        const newURL = `${location.pathname}${location.search}${newHash}`;

        history.push(newURL);
      } else {
        enqueueSnackbar('Please add meta data first.', { variant: 'error' });
      }
    },
    [getSteps, history, location.pathname, location.search],
  );

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 form-container-height">
      <div className="d-flex justify-content-center my-3">
        <Typography color="textPrimary" variant="h5">
          {!!lineItemId ? 'Edit Line Item' : 'Create New Line Item'}
        </Typography>
      </div>
      {lineItemData.expired && (
        <div className="d-flex justify-content-center textRed">
          <Typography variant="h6">This line item has been expired.</Typography>
        </div>
      )}
      <StepperComponent
        steps={getSteps()}
        activeStep={activeStep}
        setActiveStep={(index) => handleStepClick(index)}
      />
    </div>
  );
};
