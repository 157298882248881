export const ADVERTISER_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const AGENCY_CURRENCY = [
  { label: 'INR', value: 'INR' },
  { label: 'USD', value: 'USD' },
];

export const CAMPAIGN_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const CONVERSION_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];
export const LINE_ITEM_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const LINE_ITEM_PACING = [
  { label: 'UNIFORM', value: 'UNIFORM' },
  { label: 'ASAP', value: 'ASAP' },
];

export const LINE_ITEM_BIDDING_STRATEGY = [
  { label: 'Target Clearing', value: 2 },
  { label: 'Fixed Price', value: 1 },
];

export const AGENCY_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const AGENCY_TYPE = [
  { label: 'Agency', value: 'AGENCY' },
  { label: 'Company', value: 'COMPANY' },
  { label: 'Brand', value: 'BRAND' },
];

export const USER_ROLE = [
  { label: 'Admin', value: 'ADMIN' },
  { label: 'Editor', value: 'EDITOR' },
  { label: 'Viewer', value: 'VIEWER' },
  { label: 'Owner', value: 'OWNER' },
];

export const USER_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const CLUSTER_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];

export const GOALTYPE = [
  { label: 'Spend Goal', value: 'SPEND_GOAL' },
  { label: 'Impression Goal', value: 'IMPRESSION_GOAL' },
];

export const GENERATE_INVOICE = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const PLACEMENT_WISE_IMPRESSIONS_LIMIT = [
  { label: '15', value: 15 },
  { label: '20', value: 20 },
  // { label: '30', value: 30 },
  // { label: '100', value: 100 },
];
export const TARGETING_TYPES = [
  'INCLUDE_EXCLUE',
  'SELECT',
  'CHECKBOX',
  'RADIO',
  'TEXT_AREA',
  'TEXT',
];

export const OBJECTIVE_GOAL = [
  { label: 'Website traffic', value: 'WEB_TRAFFIC' },
  { label: 'Lead', value: 'LEAD' },
  { label: 'App promotion', value: 'APP_PROMOTION' },
  { label: 'Awareness and consideration', value: 'AWARENESS' },
  { label: 'Campaign with no specific objective', value: 'NO_OBJECTIVE' },
];

export const SGEMENT_STATUS = [
  { label: 'Enabled', value: true },
  { label: 'Disabled', value: false },
];
